/* global graphql */

import React from 'react';
import TemplateWrapper from '../components/TemplateWrapper';
import ContactForm from '../components/ContactForm';

class ContactPage extends React.Component {

  render() {

    const { location } = this.props;

    return (
      <TemplateWrapper location={location}>
        <main className="ContactPage">
          <section className="Section ContactPage-header">
            <div className="container-fluid">
              <div className="wrapper">
                <div className="row">
                  <div className="col-xs-14 col-md-12 col-md-offset-1"><h1 className="Heading Heading-d1 light">Contact</h1></div>
                </div>
              </div>
            </div>
          </section>
          <section className="Section ContactPage-callout">
            <div className="container-fluid">
              <div className="wrapper">
                <div className="row">
                  <div className="col-xs-14 col-md-1 col-md-offset-1 ContactPage-callout-left">
                    <svg width="24" height="24" viewBox="0 0 24 24"><path fill="#fff" d="M24 4.557a9.83 9.83 0 0 1-2.828.775 4.932 4.932 0 0 0 2.165-2.724 9.864 9.864 0 0 1-3.127 1.195 4.916 4.916 0 0 0-3.594-1.555c-3.179 0-5.515 2.966-4.797 6.045A13.978 13.978 0 0 1 1.671 3.149a4.93 4.93 0 0 0 1.523 6.574 4.903 4.903 0 0 1-2.229-.616c-.054 2.281 1.581 4.415 3.949 4.89a4.935 4.935 0 0 1-2.224.084 4.928 4.928 0 0 0 4.6 3.419A9.9 9.9 0 0 1 0 19.54a13.94 13.94 0 0 0 7.548 2.212c9.142 0 14.307-7.721 13.995-14.646A10.025 10.025 0 0 0 24 4.557z"/></svg>
                  </div>
                  <div className="col-xs-14 col-md-6 ContactPage-callout-right">
                    <p className="Special Special-s3 light ContactPage-callout-right-title">Our newest updates allow for you to connect all your devices with a single click. No questions asked. </p>
                    <p className="Caption Caption-C2 light">@HelloItsMagic</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="Section Contact-form-container">
            <div className="container-fluid">
              <div className="wrapper">
                <div className="row">
                  <div className="col-xs-14 col-md-12 col-md-offset-1">
                    <h3 className="Heading Heading-h3">Send a message to Team Magic</h3>
                  </div>
                  <div className="col-xs-14 col-md-12 col-md-offset-1">
                    <ContactForm />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </TemplateWrapper>
    );
  }

}

export default ContactPage;

