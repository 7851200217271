import React from 'react';
import { Form, Text, TextArea } from 'informed';
import isValidEmail from 'validator/lib/isEmail';

class CTAForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isNameValid: null,
      isEmailValid: null,
      isMessageValid: null
    };

  }

  render() {
    const { isNameValid, isEmailValid, isMessageValid } = this.state;

    const validateName = value => {
      if (!value) {
        this.setState({isNameValid: false});
        return 'Required field'
      } else {
        this.setState({isNameValid: true});
        return null;
      }
    };

    const validateEmail = value => {
      if (!value) {
        this.setState({isEmailValid: false});
        return "This field is required";
      } else if (!isValidEmail(value)) {
        this.setState({isEmailValid: false});
        return "Invalid email";
      } else {
        this.setState({isEmailValid: true});
        return null;
      }
    };

    const validateMessage = value => {
      if (!value) {
        this.setState({isMessageValid: false});
        return 'Required field'
      } else {
        this.setState({isMessageValid: true});
        return null;
      }
    };


    return (
      <Form className="Contact-form">
        {({ formState }) => (
        <div>
          <div className="InputBtnInside NameField">
            <label className="InputBtnInside-label InputBtnInside-label-green font-mono-regular uppercase" htmlFor="name">{formState.errors.name ? formState.errors.name : "Name" }</label>
            <Text onFocus={this.NameinputOnFocus} onBlur={this.NameinputOnFocusOut} validateOnChange validate={validateName} className={"CtaForm-input Input InputGhost InputGhost-green Input-lg NameField-input" + (isNameValid === false ? " invalid" : "")} field="name" id="name" />
          </div>

          <div className="InputBtnInside EmailField">
            <label className="InputBtnInside-label InputBtnInside-label-green font-mono-regular uppercase" htmlFor="email">{formState.errors.email ? formState.errors.email : "Email" }</label>
            <Text onFocus={this.EmailinputOnFocus} onBlur={this.EmailinputOnFocusOut} validateOnChange validate={validateEmail} className={"CtaForm-input Input InputGhost InputGhost-green Input-lg EmailField-input" + (isEmailValid === false ? " invalid" : "")} field="email" id="email" />
          </div>

          <div className="InputBtnInside MessageField">
            <label className="InputBtnInside-label InputBtnInside-label-green font-mono-regular uppercase" htmlFor="message">{formState.errors.message ? formState.errors.message : "Message" }</label>
            <TextArea onFocus={this.MessageinputOnFocus} onBlur={this.MessageinputOnFocusOut} validateOnChange validate={validateMessage} className={"CtaForm-input Input InputGhost InputGhost-green Input-lg MessageField-input" + (isMessageValid === false ? " invalid" : "")} field="message" id="message" />
          </div>

          {(formState.invalid) ?
            <button disabled className={"BtnGhost BtnGhost-large green uppercase disabled"} type="submit"><span>Send Message </span><svg width="52" height="26" viewBox="0 0 52 26"><g stroke="#FFF" fill="none" fillRule="evenodd"><path d="M.478 13H51.34M39 1l13 11.254L39 25"/></g></svg></button> :
            <button onClick={this.contactFormSubmit(formState)} className={"BtnGhost BtnGhost-large green uppercase"} type="submit"><span>Send Message </span><svg width="52" height="26" viewBox="0 0 52 26"><g stroke="#FFF" fill="none" fillRule="evenodd"><path d="M.478 13H51.34M39 1l13 11.254L39 25"/></g></svg></button>
          }

        </div>
        )}
      </Form>
    );

  }

  contactFormSubmit(formState) {

    // TODO: When Page Implemented please include segment call
    //
    // let formValues = formState.values;
    // let values = {
    //   'name': formValues.name,
    //   'email': formValues.email,
    //   'message': formValues.message,
    // };

    // window.analytics.identify(values.email, {
    //   email: values.email,
    //   name: values.name,
    //   message: values.message
    // });

    // window.analytics.track('Submitted Contact Form');
  }

  NameinputOnFocus() {
    let ctaForm_input_cont = document.getElementsByClassName('NameField')[0];
    ctaForm_input_cont.classList.add("focus");
  }
  NameinputOnFocusOut() {
    let ctaForm_input_cont = document.getElementsByClassName('NameField')[0];
    let ctaForm_input = document.getElementsByClassName('NameField-input')[0];
    if (!ctaForm_input.value) {
      ctaForm_input_cont.classList.remove("focus");
    }
  }

  EmailinputOnFocus() {
    let ctaForm_input_cont = document.getElementsByClassName('EmailField')[0];
    ctaForm_input_cont.classList.add("focus");
  }
  EmailinputOnFocusOut() {
    let ctaForm_input_cont = document.getElementsByClassName('EmailField')[0];
    let ctaForm_input = document.getElementsByClassName('EmailField-input')[0];
    if (!ctaForm_input.value) {
      ctaForm_input_cont.classList.remove("focus");
    }
  }

  MessageinputOnFocus() {
    let ctaForm_input_cont = document.getElementsByClassName('MessageField')[0];
    ctaForm_input_cont.classList.add("focus");
  }
  MessageinputOnFocusOut() {
    let ctaForm_input_cont = document.getElementsByClassName('MessageField')[0];
    let ctaForm_input = document.getElementsByClassName('MessageField-input')[0];
    if (!ctaForm_input.value) {
      ctaForm_input_cont.classList.remove("focus");
    }
  }

}

export default CTAForm;
